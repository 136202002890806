@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  background-color: #f4f4f4;
  margin: 0;
  font-family: 'Architects Daughter', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, p, div, Typeography {
  font-family: 'Architects Daughter', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
 .imgMax {
   max-width: 80%;
 }
.mainWrapper {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}



#paintings-header *{
  font-family: 'Architects Daughter', cursive;
}
.smol {
  width: 400px;
}
